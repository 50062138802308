<script>
  export let utcDateTime;
  export let dateStyle = { dateStyle: "short" };
  export let timeStyle = { timeStyle: "short" };
  export let lang = window.navigator.language;

  function handleDateTime() {
    try {
      const options = { ...dateStyle, ...timeStyle };
      return new Intl.DateTimeFormat(lang, options).format(new Date(utcDateTime));
    } catch (e) {
      console.error(`Failed to parse date: ${utcDateTime}`);
      console.error(e);
      return utcDateTime;
    }
  }

  $: result = handleDateTime();
</script>

{result}
