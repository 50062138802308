import { notyf_top_right } from "misc/notyf_top_right";
import NProgress from "nprogress";

const GENERIC_ERROR_MSG =
  "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page.";

export const getHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content")
  };
};

export const initNProgress = () => {
  const parent = document.getElementById("nprogressBar") ? "#nprogressBar" : "body";
  NProgress.configure({ parent: parent, showSpinner: false });
};

export const request = async (endpoint, params = {}) => {
  try {
    const response = await fetch(endpoint, { headers: getHeaders(), ...params });
    if (response.status === 500) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error.name, error.message);
    throw error;
  }
};

export const requestWithUiIndicators = async (endpoint, params = {}) => {
  initNProgress();

  try {
    NProgress.start();
    return await request(endpoint, params);
  } catch (error) {
    notyf_top_right.error(GENERIC_ERROR_MSG);
    throw error;
  } finally {
    NProgress.done();
  }
};
